.main {
    height: 70vh;
    display:flex;
    justify-content: space-between;
    align-items: center;
    background:transparent;
    flex-direction: column;
    margin:0;
    margin-top:70px;
    margin-bottom:170px;
}

.main-title {
    background:transparent;
    margin:0;
}

.main-title-text {
    position:relative;
    text-align:center;
    background:transparent;
    margin:0;
}

.link-bsc:hover {
    color: #383838;
}

.main-title h1 {
    animation: fadeInDown 0.5s forwards;
    opacity:0;
    display: block;
    font-family: 'SF Pro Text', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji"     !important;
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 70px;
    text-align: center;
    letter-spacing: -0.0em;
    color: var(--texttitle);
    margin-bottom:10px;
    background:transparent;
}

.main-title h3, .link-bsc {
    animation: fadeInDown 0.5s forwards;
    animation-delay:0.5s;
    opacity:0;
    display: inline-block;
    font-family: 'SF Compact';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    /* identical to box height, or 350% */

    text-align: center;
    color: #383838;
    background-color: #F389FD;
    margin-top:40px;
    padding:0px 10px;
}


.desc-h3 {
    display:flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    margin:0px 20px;
}

.main-title span {
    animation: fadeInDown 0.5s forwards;
    opacity:0;
    display: inline-block;
    white-space: nowrap;
    font-family: 'SF Pro Text', ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji"     !important;
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 70px;
    text-align: center;
    color: var(--texttitle);
    background:transparent;
}

.br {
    display: block;
    margin-bottom: -10px;
}


.main-title-buttons {

    animation: fadeInDown 0.5s forwards;
    animation-delay:1s;
    opacity:0;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background:transparent;
    margin-top:-20px;
}

.main-title-buttons button {
    width: 218px;
    height: 70px;
    border-radius: 50px;
    font-family: 'SF Pro Display', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    margin: 10px;
}

.main-title-buttons > * {
    margin:0;
    background:transparent;
}

button {
    cursor:pointer;
}

.main-title-buttons-blog {
    border: 1px solid var(--text-primary);
    background: var(--text-primary);
    color: var(--background);
}

.main-title-buttons-blog:hover {
    border: 1px solid var(--background);
    border-image-outset:30px;
}

.main-title-buttons-proj {
    border: 1px solid var(--text-primary);
    color: var(--text-primary);
    background:transparent;
}

.main-title-buttons-proj:hover {
    border: 2px solid var(--text-primary);
}

.main-title-text {
    margin-bottom: 4vh;
}

.dwn-arrow {
    z-index: 22;
    background: transparent;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.dwn-arrow p {
    font-size: 20px;
    font-weight: 400;
    color:var(--text-subtitle);
    background: transparent;
}

.dwn-arrow img {
    background: transparent;
    width: 26px;
    height: 40px;
}

.main-title h1 {
    width:1170px;
}


@media (max-width: 1200px) {
    .main {
        justify-content: space-between;
        align-items: center;
        height: 75vh;
        margin-bottom:15vh;
    }

    .main-title {
        margin:0;
    }

    .main-title h1 {
        width:870px ;
        font-size:60px;
        line-height: 60px;
        margin-top:20px;
        margin-bottom:10px;

    }

    .main-title h3, .link-bsc {
        font-size: 18px;
        margin-top:20px;
        margin-bottom:10px;
    }

    .main-title-buttons button {
        width: 180px;
        height:70px;
        border-radius: 50px;
        font-family: 'SF Pro Display', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        margin: 2px;
    }

    .dwn-arrow {
        margin-left:2vw;
    }

    .dwn-arrow p {
        display:none;
    }

}

.link-bsc {
    text-decoration: none;
    margin:0;
    padding:0;
}

@media (max-width: 930px) {
    .main-title h1 {
        width: 100%;
        font-size: 50px;
        line-height: 50px;
        margin-top:20px;
        margin-bottom:10px;
    }

    .main {
        margin-top:-30px;
    }
}

@media (max-width:900px) {

    .main-title {
        margin-top:5vh;
    }

    .main-title h3, .link-bsc {
        font-size: 14px;
        line-height: 17px;
    }

    .main-title-buttons {
        flex-direction: column;
    }
}

@media (max-width:750px) {
    .main-title h1 {
        width:90%;
        font-size: 54px;
        line-height: 54px;
        margin-top:40px;
        margin-bottom:20px;
    }
}

@media (max-width:471px) {
    .main-title h1 {
        width: 100%;
        font-size: 10vw;
        line-height: 10vw;
        margin-top:40px;
        margin-bottom:20px;
    }
}
@keyframes fadeInDown {
    from {
        opacity:0;
        /* transform: translateY(-60px); */
    }
    to {
        /* transform: translateY(0); */
        opacity: 1;
    }
}
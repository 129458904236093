.main-blog {
    margin: 0vh 2vw;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}

.main-blog-title {
    width: 488px;
    margin-top:5vh;
}

.main-blog-title-header {
    font-family: 'SF Compact';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 30px;

    color: var(--text-primary);
}

.main-blog-title-subheader {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
    line-height: 41px;

    color: var(--accent);
}

.main-blog-img > img {
    animation: fadeInDown 0.5s forwards;
    /* animation-delay:0.5s; */
    opacity:0;
    min-width:0;
    width: 30vh;
    height:30vh;
}

.main-blog-title-input {
    /* animation: fadeInDown 0.5s forwards;
    animation-delay:0.2s;
    opacity:0; */
    margin-top: 6vh;
    display:flex;
    flex-direction: row;
    align-items: center;
}

.main-blog-title-input > * {
    margin:0;
}

.main-blog-title-input > img {
    margin-left:23px;
}


.main-blog-title-input > input {
    width: 12vw;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 27px;

    padding: 2vh 6vw;
    position:absolute;
    border: 2px solid #9195AF;
    border-radius: 50px;
    padding-left:3vw;
    background: transparent;
    padding-left:53px;
}

.main-blog-title-input > input::placeholder {
    color: var(--placeholder);
}

@media (max-width: 1000px) {
    .main-blog-title {
        width: auto;
    }

    .main-blog {
        align-items: center;
    }

    .main-blog-img > img {
        width:30vw;
        height:auto;
    }

    .main-blog-title-subheader {
        font-size: 24px;
        line-height: normal;

        color: var(--accent);
    }

    .main-blog-title-input > input {
        width: 30vw;
    }
}

@media (max-width: 800px) {

    .main-blog-title-input > input {
        width: 30vw;
        height: 1vh;

        font-size: 17px;
    }

    .main-blog-title-input > img {
        width: 17px;
        height:auto;
    }

    .main-blog {
        margin-left:10px;
        margin-top: 2vh;
    }

    .main-blog-title-header {
        font-size: 27px;
    }

    .main-blog-title-subheader {
        font-size: 18px;
    }
}

@media (max-width: 700px) {
    .main-blog-title-input > input {
        width: 35vw;
        height: 12px;

        font-size: 17px;
    }
}

:root {
    /* --background: #191920;
    --text-primary: white;
    --text-secondary: #abababc8;
    --accent: #9195AF;
    --footer-title: #E8EAF2;
    --footer-text: #7D8097;
    --placeholder: rgba(145, 149, 175, 0.23);
    --topic-bg: #24262F;
    --topic-fg: #5B5E71;
    --proj-head: #C2C8F3;
    --topic-proj-bg: #272a36;
    --textbody: #ffffffdf;
    --texttitle: rgba(255,255,255,0.9);
    --text-subtitle: rgba(255,255,255,0.6);
    --text1: #86ff864d;
    --proj-head: #C2C8F3;
    --proj-head-t: rgba(194, 200, 243, 0.2); */

    --background: white;
    --text-primary: black;
    --text-secondary: #847f7f;
    --accent: #40445c;
    --footer-title: #3d3d3d;
    --footer-text: #3d3d3d;
    --placeholder: rgba(51, 53, 66, 0.23);
    --topic-bg: #f1f1f5;
    --topic-fg: #9b9999;
    --proj-head: #0f164d;
    --topic-proj-bg: #e8eaf2;
    --textbody: black;
    --texttitle: rgba(0,0,0,0.8);
    --text-subtitle: rgba(0,0,0,0.8);
    --text1: #0eac0e;
    --proj-head-t: rgba(15, 22, 77, 0.07);
}

* {
    background-color: var(--background);
    color:var(--text-primary);
    font-family: 'SF Pro Display', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-size: 24px;
    margin: 10px 1.5vw;
}

html {
    overflow:unset;
    position: static;
}

a {
    text-decoration: none;
}

.flare {
    position: absolute;
    width:90vw;
    /* width: 1211.2px;
    height: 1069.59px;

    background: url(../../public/Mesh-Grad.png); */
    display:flex;
    justify-content: center;
    margin:0;

    background: transparent;
    margin-top:-340px;

}


.flare img {
    opacity:0;
    animation: fadeInDownAux 1s forwards;
    animation-delay:0.5s;
}

@media (max-width: 1375px) {
    .flare {
        width: 90vw;
        height: 90vw;
        margin-top:-10vw;
    }
}

@media (max-width: 1100px) {
    * {
        margin: 10px 1vw;
    }


}

@media (max-width: 700px) {
    * {
        margin: 5px 2px;
    }

    .flare {
        margin-left:-5px;
        width: 98vw;
        height: 190vw;
        margin-top:-5vw;
        overflow:hidden;
    }

}


@keyframes fadeInDownAux {
    from {
        opacity:0;
        /* transform: translateY(-60px); */
    }
    to {
        /* transform: translateY(0); */
        opacity: 1;
    }
}

.topics-bot {
    display:flex;
    align-items: center;
}


.topics-title {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    margin-left: 10px;
    margin-bottom: 2.5vh;   
    color: var(--text-primary);
}

.topics-tags {
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-left:0;
    justify-content: flex-start;
    align-items: flex-start;
}
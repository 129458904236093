.project-list {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: auto;
    margin-top:10vh;
    margin-left:3vw;
    overflow: visible;
    flex-wrap:wrap;
    gap:3rem;
    margin-bottom: 140px;
}



@media (max-width: 1650px) {
    .project-list {
        flex-wrap: wrap;
        gap:1.5rem;
    }
}

@media (max-width: 1250px) {
    .project-list {

        gap:4vh;
    }
}

@media (max-width: 1000px) {
    .project-list {
        gap:4vh;
        margin-top:5vh;
    }
}

@media (max-width: 750px) {
    .project-list {    
        gap:50px;
        justify-content: flex-start;
        margin-left:7vw;
        flex-wrap: wrap;
    }
}

@media (max-width: 700px) {
    .project-list {
        margin-left:2.5rem;
        flex-wrap: wrap;
    }
}

@media (max-width: 650px) {
    .project-list {
        margin-left:0;
        flex-wrap: wrap;
    }
}

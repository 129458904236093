.hr-footer {
    margin:0 -6vw;
    display: block; height: 1px;
    border: 0; border-top: 1px solid #7D8097;
    padding: 0;
    margin-bottom:12vh;
}

.footer {
    display: flex;
    justify-content: space-evenly;
    align-items:baseline;
    margin:0 10vw;
    margin-bottom:20vh;
}

.footer-title-text {
    width:240px;
    margin-top:5vh;
    margin-left:5vw;
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;

    color: var(--text-secondary);
}

.footer-title {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 26px;

    color: var(--footer-title);
    margin-bottom:2vh;

    white-space: nowrap;
}

.footer-cristian > .footer-title {
    font-size: 32px;
    line-height: 38px;
}

.footer-title-body, .footer-title-body > a {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;

    white-space: nowrap;

    color: var(--footer-text);

}

.footer-title-body > a {
    color: var(--footer-text);
    line-height: 13px;
}

.footer-title-body > img {
    width: 118px;
    display:block;
}

.footer-links {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}

.footer-title-body > a {
    margin:5px 0;
}

/* .footer-links {
    margin:0;
} */

.footer-title-body > a:after {
    background-color: var(--background);
}

.footer-contact > .footer-title-body, .footer-sitemap > .footer-title-body {
    line-height: 7px;
}

.footer a:hover {
    color:var(--text-primary);
}


@media (max-width: 1650px) {
    .footer {
        margin:0;
        margin-left:2vw;
        margin-bottom:15vh;
        flex-wrap: nowrap;
        justify-content: flex-start;
    }

    .footer-title-text {
        width:320px;
        margin-left:2vw;
    }
}

@media (max-width: 1250px) {
    .footer {
        margin-left:2vw;
        flex-wrap: nowrap;
        width: auto;
    }

    .footer-title-text {
        width:auto;
        margin-left:2vw;
    }
}

@media (max-width: 1050px) {
    .footer {
        margin-left:2vw;
        flex-wrap: wrap;
        width: auto;
    }
}


@media (max-width: 800px) {
    .hr-footer {
        margin: 7vh -20px;
    }

    .footer {
        margin:0 auto;
        margin-left:5vw;
        margin-bottom:15vh;
        flex-wrap: wrap;
        justify-content: flex-start;

    }

    .footer-title-text {
        width:100vw;
        max-width: 100%;
        margin-left:2vw;
    }
}
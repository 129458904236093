.contact {
    margin-top:5vh;
    height:80vh;
}

.contact a {
    margin-left:2vw;
    font-family: 'SF Pro Display', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-size:5.2vw;
    color: var(--text-primary);
    min-width: 0; min-height: 0;
    overflow-wrap: break-word;
    word-break: break-word;
    background:transparent;
    opacity:0.4;
    font-weight: 500;
    border:3px solid var(--topic-fg);
    border-radius: 20px;
    padding:0.2vw 2vw;
    background-color:var(--topic-bg);
    transition:opacity 0.5s, transform 0.5s;
}

.contact a:hover {
    opacity: 1; transform: translateX(1vw);
}

.contact a:last-of-type {
    margin-bottom:10vw;
}


@media (max-width: 1300px) {
    .contact {
        height:auto;
    }
}

@media (max-width: 900px) {
    .contact {
        width:90vw;
        height:auto;
    }

    .contact a {
        color: var(--text-primary);
        opacity:1;
        border:3px solid var(--topic-fg);
        border-radius: 10px;
        padding:0.2vw 2vw;
        background-color:var(--topic-bg);
        transition: none;
        font-size:8vw;
        opacity: 1; transform: translateX(0vw);
    }

    .contact a:hover { 
        transition: none;
        opacity: 1; transform: translateX(0vw);
    }
}
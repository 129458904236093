.Blog-rec {
    margin:0 auto;
    width:70vw;
    margin-bottom: 15vh;
}

.event-list {
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    margin-left:3vw;
}

.header {
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3vh;
}

.header-title-1 {
    font-family: 'SF Compact';
    font-style: normal;
    font-weight: 556;
    font-size: 40px;
    line-height: 48px;
    /* identical to box height */
    
    
    color: var(--text-primary);
    
}

.header-title-2 {
    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 38px;

    color: var(--accent);
}

.pres-text-arrow-blog-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin:0;
    gap:0;
}

.pres-text-arrow-blog-list > div {
    padding:0;
    margin:0;
}

.pres-text-arrow-blog-list img {
    width: 50px;
    margin-right:2vw;
    transform: rotate(-90deg);
}

.main-title-learn-more-text-blog-list {
    font-family: 'SF Pro Display', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;    
    line-height: 26px;

    white-space: nowrap;
}

@media (max-width: 1650px) {
    .header {
        margin-right:0vw;
    }
    .Blog-rec {
        width:auto;
    }
}

@media (max-width: 1250px) {

    .event-list {
        margin-left:3vw;
    }

    .event-card-desc-date {
        font-size: 17px;
    }

    .event-card-desc-title {
        font-size: 25px;
    }

    .header {
        margin-left:2vw;
        margin-right:2vw;
        margin-bottom: 1vh;
    }

    .header-title-1 {
        font-size:33px;
    }

    .header-title-2 {
        font-size:28px;
    }

    .pres-text-arrow-blog-list {
        margin: 2vh 0px;
    }

}

@media (max-width: 1000px) {
    .event-list > *:last-child {
        display:none;
        flex:none;  
        /* width:0px; */
        position: absolute;
        /* flex: 0 0 100%; */
    }
    .event-card-img {
        width:40vw;
    }
}

@media (max-width: 850px) {
    .event-card-img {
        width:40vw;
    }

    .main-title-learn-more-text-blog-list {
        display:none;
    }

    .event-card-desc-date {
        font-size:14px;
        line-height: 20px;
    }

    .event-card-desc-title {
        font-size:20px;
        line-height: 22px;
    }

    .header {
        margin-bottom: 0vh;
    }

    .header-title-1 {
        font-size:5vw;
        line-height: 4vw;
    }

    .header-title-2 {
        font-size:3.8vw;
    }
}
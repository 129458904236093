.proj-list {
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    margin-left:3vw;
    overflow: visible;
    margin-bottom:220px;
}

.Blog-rec-proj {
    margin:0 auto;
    width:70vw;
    margin-bottom:20vh;
}

.header-proj {
    display:flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3vh;
}

@keyframes fadeOutProjs {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@media (max-width: 1650px) {
    .Blog-rec-proj {
        width:auto;
    }
}

@media (max-width: 1250px) {
    .proj-list {
        
        justify-content: center;
        gap:4vh;
        margin:0;
    }
}

@media (max-width: 1000px) {
    .proj-list {
        justify-content: center;
        gap:4vh;
        margin:3vh auto;
    }
}

@media (max-width: 750px) {
    .proj-list {    
        gap:50px;
        justify-content: flex-start;
        margin-left:7vw;
        flex-wrap: wrap;
    }
}

@media (max-width: 700px) {
    .proj-list {
        margin-left:2.5rem;
        flex-wrap: wrap;
    }
}

@media (max-width: 650px) {
    .proj-list {
        margin-left:0;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.pres {
    display:flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-bottom:170px;
    margin-top:200px;
    background:transparent;
    position: relative;
    z-index:33;
}

br {
    margin:0;
    line-height: 10px;
}

.pres > * {
    margin:0;
    background:transparent;

}


.vl {
    border-left: 1px solid var(--accent);
    height: auto;
    margin: 0px 20px;
}

.pres-text {
    width:520px;
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    background:transparent;
}

.pres-text-title {
    font-family: 'SF Pro Display', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    color: var(--text-primary);
    background:transparent;

}

.pres-text-body {
    font-family: 'SF Pro Display', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    color: var(--accent);
    background:transparent;
}

.pres-text-body p {
    font-family: 'SF Pro Display', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    color: var(--accent);
    background:transparent;
    margin: 12px 0px;
}

.pres-text-body-first {
    margin:0;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background:transparent;
    margin-bottom:-24px;
}

.pres-text-arrow-pres {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap:0;
}

.pres-text-arrow-pres > div {
    margin-left:0px;
    /* margin-right: -5px; */
}

.pres-text-arrow-pres img {
    width: 40px;
    margin-left:-5px;
    transform: rotate(-90deg);
}

.main-title-learn-more-text {
    margin-left:12px;
    font-family: 'SF Pro Display', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
}

.Luna {
    background:transparent;
}

.Luna img {
    background:transparent;
}

@media (max-width: 1100px) {
    .vl {
        display:none;
        height: 0vh;
        margin: 0px 0px;
    }

    .pres {
        flex-direction: column;
        margin: 0vh 5vw;
        justify-content: space-evenly;
        align-items: flex-start;
        margin-bottom:5vh;
    }

    .Luna img {
        width: 40vw;
    }

    .Luna {
        margin-bottom:0vh;
        align-self: center;
    }

    .pres-text {
        justify-content: flex-start;
    }

    .pres-text-title {
        width:80vw;
        font-size:28px;
        margin: 3vh 0px;
    }

    .pres-text-body {
        width:75vw;
        font-size:20px;
    }

    .pres-text-arrow-pres {
        margin: 2vh 0px;
    }

    .pres-text-arrow-pres img {
        margin-left:5px;
    }
}

@media (max-width: 900px) {

    .pres-text {
        width:auto;
        height: auto;
        justify-content: flex-start;
    }

    .Luna {
        margin-bottom:0vh;
        align-self: center;
    }

    .Luna img {
        width: 55vw;
    }
}

@media (max-width: 300px) {
    .main-title-text > span {
        font-size:28px;
    }
}
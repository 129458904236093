@font-face {
    font-family: "Mattone";
    src: url("../../../public/Mattone-150.woff2") format("woff2"),
         url("../../../public/Mattone-150.woff") format("woff");
}

.vid {
    display:flex;
    justify-content: center;
    background:black;
    position:static;
    margin:0;
    width:100vw;
    margin-left:-7.5vw;
    /* border-width: 1px 0px;
    border-style: solid;
    border-color: var(--topic-fg); */
    margin-bottom:70px;
    height: max-content;
}

.vid video {
    animation: fadeInDown 2.5s forwards;
    /* animation-delay: 0.5s; */
    opacity:0;
    background:black;
    margin:0;
    width:40vw;

}

.about-flex {
    display:flex;
    flex-direction: row;
    width:99%;
    justify-content: space-between;
}

.about-body {
    width:auto;
    margin:0;
    width:99%;
}

.about-body p {
    color: var(--text-primary);
    font-size:18px;
}

.about-body h1 {
    color: var(--text-primary);
    font-size:43px;
    text-transform: uppercase;
}

.about-body hr {
    margin-bottom:1vh;
    color: var(--text-primary);
}

.item4 { grid-area: body1; }
.item5 { grid-area: body2; }
.item6 { grid-area: body3; }

.text2 { grid-area: text2; }
.text3 { grid-area: text3; }


/* .item1, .item2, .item3 {
    color: var(--text-primary);
    font-size:43px;
    text-transform: uppercase;
}

.item4, .item5, .item6 {
    color: var(--text-primary);
    font-size:18px;
} */

.grid-container {
  display: grid;
  grid-template-areas:
    'body1 text1 text1'
    'text3 body2 text2'
    'text3 text4 body3';
    gap:0px;
    margin:0;
    margin-top:-10vh;
    margin-bottom:-5vh;
    background:transparent;
    grid-auto-columns: 1fr;
    grid-auto-flow: row;
}

.grid-container > .item4, .grid-container > .item5, .grid-container > .item6 {
    margin:0;
    position: sticky;
    top:0;
    background:transparent;
    padding-top:5vh;
    height:100vh;
}

.grid-container > .item4 > h1, .grid-container > .item5 > h1, .grid-container > .item6 > h1 {
    color: var(--text-primary);
    font-size:43px;
    text-transform: uppercase;
    background:transparent;
}

.grid-container > .item4 > p, .grid-container > .item5 > p, .grid-container > .item6 > p, .grid-container p > span {
    color:var(--textbody);
    font-size:2vh;
    background: transparent;
}

.grid-container p > span {
    margin:0 3px;
    padding: auto 2vw;
    background-color:var(--proj-head-t);
    color: var(--proj-head);
    box-shadow: 3px 0 0 var(--proj-head-t), -3px 0 0 var(--proj-head-t);
    font-weight: 500;
}

.grid-container p > span > * {
    padding:10px;
}


.text1, .text1>* {
    margin-right:-5vw;
    margin-top:95px;
    grid-column: span 2;
    white-space:pre-wrap;
    font-family: 'Mattone' !important;
    font-size:7.5vw;
    line-height:7.5vw;
    color: transparent;
    min-width: 0; min-height: 0;
    align-self: center;
    -webkit-text-stroke-width: 2px;
    /* -webkit-text-stroke-color: var(--topic-fg); */
    -webkit-text-stroke-color: var(--topic-fg);
    overflow-wrap: break-word;
    word-break: break-all;
}

.text2 {
    margin-right:0vw;
    margin-top:25px;
    margin-bottom:-85px;
    white-space:pre-wrap;
    font-family: 'Mattone' !important;
    font-size:5vw;
    line-height:5vw;
    font-size:7.5vw;
    line-height:7.5vw;
    color: transparent;
    min-width: 0; min-height: 0;
    align-self: center;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: var(--topic-fg);
    overflow-wrap: break-word;
    word-break: break-word;
}

.text3, .text4 {
    margin-left:4vw;
    margin-top:-70px;
    white-space:pre-wrap;
    font-family: 'Mattone' !important;
    font-size:25vw;
    color: var(--background);
    min-width: 0; min-height: 0;
    align-self: center;
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: var(--accent);
    overflow-wrap: break-word;
    word-break: break-word;
    background:transparent;
}

@media (max-width: 1000px) {
    .text1, .text2, .text3, .text4 { display:none; }

    .grid-container {
        display:flex;
        margin:5vw;
        flex-direction: column;
    }

    .grid-container > .item4, .grid-container > .item5, .grid-container > .item6 {
        background:transparent;
        position:static;
        height:auto;
        padding-top: 0;
        margin-bottom: 40px;
    }

    .grid-container > .item4 > p, .grid-container > .item5 > p, .grid-container > .item6 > p {
        color:var(--textbody);
        font-size:19px;
        line-height:24px;
        background: transparent;
    }

    .vid {
        margin-left:-7.5vw;
        /* border-width: 1px 0px;
        border-style: solid;
        border-color: var(--topic-fg); */
        margin-bottom:30px;
        height: max-content;
    }

    .vid video {
        animation: fadeInDown 2.5s forwards;
        animation-delay: 0s;
      /* animation-delay: 0.5s; */
      opacity: 0;
      background: black;
      margin: 0;
        margin-right: 0px;
      padding-right: 2.5vw;
      width: 95vw;
      margin-right: -12vw;

    }

}

@media (max-width: 800px) {

    .grid-container > .item4 > p, .grid-container > .item5 > p, .grid-container > .item6 > p {
        color:var(--textbody);
        font-size:16px;
        line-height:19px;
        background: transparent;
    }

}
.event-card-blog {
    max-width: min-content;
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: 0px 0vw;
}

.event-card-img {
    width: 300px;
    height: 430px;
    display:flex;
}

.event-card-img img {
    object-fit: cover;
    width: 100%;
    min-height: 100%;
}

.event-ring {
    position: relative;
    width:100%;
    max-width: 100%;
    height: 100%;
    background: transparent;
    margin:0 auto;
    align-self: center;
}

.event-ring:hover {
    animation: fadeInDown 0.3s forwards;
    opacity:0;
    border: 2px solid var(--text-primary);
    border-radius: 15px;
    padding: 4px 6.4px;
    margin: -4px -6.4px;
}


.event-card-desc {
    margin: 10px 0.5vw;
}

.event-card-desc-featured {
    margin: 20px 0.5vw;
}

.event-card-desc-date {
    font-family: 'SF Pro Display', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-style: normal;
    font-weight: 550;
    font-size: 20px;

    color: var(--text-secondary);
}

.event-card-desc-title {
    font-family: 'SF Compact', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;

    color: var(--text-primary);
}

@media (max-width: 1250px) {
    .event-card-img {
        width:25vw;
        height: 40vh;
    }

    .event-card-desc-date {
        font-size: 16px;
    }

    .event-card-desc-title {
        font-size: 22px;
    }
}

@media (max-width: 800px) {
    .event-ring:hover {
        animation: none;
        border: 0px solid var(--text-primary);
    }
}

@media (max-width: 650px) {
    .event-card-img {
        width:25vw;
        height: 30vh;
    }

    .event-card-desc-date {
        font-size: 10px;
    }

    .event-card-desc-title {
        font-size:18px;
    }
}

@keyframes fadeInDown {
    from {
        opacity:0;
        /* transform: translateY(0px); */
    }
    to {
        opacity: 1;
        /* transform: translateY(-60px); */
    }
}
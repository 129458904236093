

.proj-card-desc {
    width: 330px;
    height: 494px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: column;
    margin: 0;
    gap:2vw;
    background:transparent; 
    position:relative;
}

.proj-card-desc-cat {
    background:transparent;
    font-size:13px;
    font-weight: 600;
    text-transform: uppercase;
    color:white;
}

.proj-card-desc-top {
    margin:3vh 0.25vh;
    margin-bottom:0;
    background:transparent;
}

.proj-card-desc-bottom {
    height: auto;  
    margin:3.5vh 0vh;
    background:transparent;
    text-overflow: ellipsis;
    overflow: hidden;
}

.proj-card-desc-date {
    font-family: 'SF Pro Display', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-style: normal;
    font-weight: 550;
    font-size: 20px;
    line-height: 26px;
    background:transparent;
    color: #abababc8;
}

.proj-card-desc-title {
    font-family: 'SF Compact', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 57px;
    line-height: 90%;
    background:transparent;
    color:white;
}

.proj-card-desc-sub {

    font-family: 'SF Pro Display', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
    align-self:flex-start;

    background:transparent;

    color: rgb(255, 255, 255);
}


@media (max-width: 1250px) {
    .proj-card-desc {
        width:270px;
        height: 42vh;
    }
    
    .proj-card-desc-title { 
        font-size:40px;
    }

    .proj-card-desc-sub {
        font-size:16px;
    }

    .proj-card-desc-bottom {
        height:auto;
    }

    .proj-card-desc-top {
        margin:1vh 1vh;  
    }

    /* .proj-card-desc-date {
        font-size: 16px;
    }

    .proj-card-desc-title {
        font-size: 22px;
    } */
}

@media (max-width: 950px) {
    .proj-list {
        flex-direction: column;
        gap:2vh;
    }

    .proj-card-desc {
        width:220px;
        height: 32vh;
    }
    
    .proj-card-desc-title { 
        font-size:30px;
    }

    .proj-card-desc-sub {
        font-size:14px;
        line-height: 110%;
    }

    .proj-card-desc-bottom {
        height:auto;  
        margin: 0.25vh 1vh;
        margin-top: 0;  
    }
}

@media (max-width: 650px) {
    .proj-card-desc {
        margin:0;
        width: 90vw;
        height: 260px;
    }

    .proj-card-desc-top {
        margin:3vh 2.5vh;
        margin-bottom:0;
        background:transparent;
    }
    
    .proj-card-desc-bottom {
        height:auto;
        margin:3vh 2.5vh;
        background:transparent;
    }

    .proj-card-desc-title { 
        font-size:32px;
    }

    .proj-card-desc-sub {
        font-size: 17px;
        line-height: 110%;
    }
}

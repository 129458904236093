.blogPost {
    min-height: 599px;
}

.blogPost-header {
    border-width: 1px 0px;
    border-style: solid;
    border-color: var(--text-secondary);

    position: absolute;
    width: 100vw;
    margin: auto 0px;
    left: 0;
    display:flex;
    justify-content: flex-end;
    align-content: center;
    height: 399px;
    background-color:var(--background);
    max-width: 100%;
}

.blogPost-header-title {
    flex-basis:28%;

    font-family: 'SF Compact Display';
    font-style: normal;
    font-weight: 700;
    /* font-size:8vh; */
    font-size:320%;
    line-height: 100%;
    color:var(--text-primary);
    align-self:center;
    background:transparent;
}

.blogPost-header-title-proj {
    flex-basis:28%;

    font-family: 'SF Compact Display';
    font-style: normal;
    font-weight: 700;
    /* font-size:8vh; */
    font-size:420%;
    line-height: 100%;
    color:var(--text-primary);
    align-self:center;
    background:transparent;
}

.github-link {
    align-self: flex-start;
    border-bottom: 2px solid var(--accent);
    height: 30px;
    white-space: nowrap;
}

.blogPost-header-img {
    margin:0;
    flex-basis: 55%;
}

.blogPost-header-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    margin:0;
}

.blogPost-header-sub {
    display:flex;
    flex-direction: row;
    flex-wrap:nowrap;
    padding-top:499px;
    align-content: flex-start;
    margin-bottom:80px;
}

.blogPost-header-sub-pair {
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-self: flex-start;
}

.blogPost-header-sub-pair > * {
    margin-bottom:0;
    margin-top:0;
    font-size: 30px;
    font-weight: 400;
    line-height: 40px;
    font-family: 'SF Compact Display';
    font-style: normal;
}

.span-topics {
    font-size: 30px;
    line-height: 40px;
    font-weight: 400;
    font-family: 'SF Compact Display';
    font-style: normal;
    margin:0;
}

.blogPost-header-sub-pair > div:first-child {
    font-weight: 700;
}

.blogPost-header-sub-pair:first-child > div:last-child {
    white-space: nowrap;
}

.blogPost-body {
    margin:auto 10vw;
}

.category-capitalize {
    text-transform: capitalize;
}

.body-text {
    margin:50px 3vw;
}

.text-val {
    margin:35px 1vw;
}

.text {
    white-space: pre-line;
    color:var(--textbody);
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
}

.text-bold {
    white-space: pre-line;
    font-weight: bold;
    font-size: 32px;
    margin-bottom: -24px;
    margin-top: 40px;
}

.text-underline {
    white-space: pre-line;
    text-decoration: underline;
    font-size: 27px;
    margin-bottom: -25px;
    margin-top: 40px;
}

.text-code {
    margin-top: 24px;
    white-space: pre-wrap;
    font-size: 17px;
    line-height: 30px;
background-color: var(--topic-bg);
color: var(--proj-head);
padding: 20px 40px;
font-family: 'Menlo', 'Monaco', 'Consolas', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
}

.asset {
    white-space: pre;
    display:flex;
    justify-content: center;
    margin: 40px auto;
}

.asset-img {
    height:20%;
    max-width:80%;
}


@media(max-width: 1500px) {
    .blogPost-body {
        margin:auto 5vw;
    }
}

@media (max-width: 1250px) {
    .blogPost-body {
        margin:auto 0;
    }
}

@media (max-width: 1200px) {
    .blogPost-header-title {
        flex-basis:40%;
    }

    .blogPost-header-sub-pair:first-child > div:last-child {
        white-space: unset;
    }

    .blogPost-header-sub {
        margin-bottom: 30px;
      }
}

@media (max-width: 900px) {
    .blogPost-header-title {
        flex-basis:45%;
    }

    .text {
        font-size: 17px;
        line-height: 22px;
    }

    .text-bold {
        font-size: 20px;
        margin-top:30px;
        margin-bottom: -10px;
    }

    .text-code {
        margin-top:14px;
        white-space: pre-wrap;
        line-height: 120%;
        font-size: 12px;
        padding: 10px 10px;
    }

    .asset {
        margin: 20px auto;
    }

    .asset-img {
        width:100%;
        max-width: 100%;
    }
}

@media (max-width: 700px) {
    .blogPost-header-title {
        font-size:8vw;
        flex-basis:60%;
    }

    .blogPost-header-title-proj {
        font-size:10vw;
        flex-basis:60%;
    }

    .blogPost-header {
        height: 50vw;
    }

    .blogPost-header-sub {
        padding-top:57vw;
        justify-content: space-around;
    }

    .blogPost-header-img {
        flex-basis:35%;
    }

    .blogPost-header-sub-pair > * {
        font-size: 4vw;
        line-height: 5vw;
    }

    .github-link {
        height: 14px;
        line-height: auto;
    }

    .span-topics {
        font-size: 4vw;
        line-height: 5vw;
    }
}

.blog-list {
    margin: 5vh auto;
    margin-left:2vw;
    width:auto;
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    row-gap: 4vh;
}

@media (max-width: 1250px) {
    .blog-list {
        margin-left: 0vw;
        justify-content: flex-start;
        width:auto;
        margin: 2vh 20px;
    }
}

@media (max-width: 1000px) {
    .blog-list {
        margin: 0.25vh 20px;
    }
}

@media (max-width: 800px) {
    .blog-list {
        justify-content: space-evenly;
        margin: 0.25vh auto;
    }
}


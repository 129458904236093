
.topic {
    cursor:pointer;
    padding: 9px 1.2vw;
    /* width: 152px;
    height: 65.03px; */
    border-radius: 40px;
    border: 1px solid var(--topic-bg);
    background: var(--topic-bg);

    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 18px;

    color: var(--topic-fg);
    margin: 5px;

    user-select: none;
}

.topicSelected {
    cursor:pointer;
    padding:9px 1.2vw;
    /* width: 152px;
    height: 65.03px; */
    border-radius: 40px;
    border: 1px solid var(--accent);
    background: var(--topic-bg);

    font-family: 'SF Pro Display';
    font-style: normal;
    font-weight: 400;
    font-size:17px;
    line-height: 18px;

    color: var(--text-primary);
    margin: 5px;

    user-select: none;
}

@media (max-width: 1000px) {
    .topic {
        padding: 8px 1.5vw;
        font-size: 16px;
        margin: 5px 5px;

    }

    .topicSelected {
        padding: 8px 1.5vw;
        font-size: 16px;
        margin: 5px 5px;
    }
}
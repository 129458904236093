.slider {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom:202px;
}

.slider > span {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--text-secondary);
    font-weight: 400;
    font-size: 22px;
    font-style: italic;
    margin-bottom:32px;
}

.tech-slider {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width:80vw;
    height:50px;
}

.tech-slider > img {
    max-width:100%;
    max-height: 100%;
    width:80px;
    background: transparent; 
    opacity: 1;
    filter: grayscale(1);
}

.tech-slider > img:hover {
    /* animation: grayscale 0.2s forwards; */
    opacity: 1;
    filter: grayscale(0);
}

@media (max-width: 1000px) {
    .tech-slider {
        width:95vw;
        height:32px;
        justify-content: space-evenly;
    }

    .tech-slider > img {
        width:6vw;
    }

    .slider > span {
        margin-bottom:16px;
    }
}

/* @media (max-width: 800px) {
    .tech-slider {
        width:95vw;
    }
} */
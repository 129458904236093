.nav {
    width:auto;
    display:flex;
    align-items: center;
    justify-content: space-between;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style-type: none;
    display: inline-block;
}

.nav-title {
    white-space: nowrap;
}

.nav-title > a {
    font-family: 'SF Pro Display', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 33px;
}

.nav-links {
    display: flex;
    align-items: center;
    gap: 10px;
}

.nav-right {
    display: flex;
    justify-content: space-evenly;
    gap:6vw;
}

.nav-links > a {
    font-family: 'SF Compact Display', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 24px;
    color: var(--text-primary);
    background:transparent;
    opacity:0.8;
}
/* 
.nav-links > img {
    width: 50px;
    cursor: pointer;
} */

.nav-imgs {
    display: inline-block;
    white-space: nowrap;
}

.nav-imgs > img {
    position:relative;
    width: 50px;
    cursor: pointer;
}

a {
    display: inline-block;
    position: relative;
    color:var(--text-primary);
    padding-bottom: 5px;
    background:transparent;
}

a:hover {
    color:var(--text-primary);
    opacity:1;
}

.a:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: var(--text-primary);
    transform-origin: bottom right;
    transition: transform 0.3s ease-out;
}

a:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.nav-imgs > img {
    color: var(--text-primary);
    background:transparent;
}

.bullet-list {
    display:flex;
    flex-direction: column;
    flex-wrap: nowrap;
    height:80vh;
    justify-content: flex-start;
    margin:5vw;
    margin-top:0px;
    position:static;
    z-index: 10;
    width: 80%;
}

.btn_activated {
    height: 22px;
    color: var(--text-primary) !important;
    font-weight: 600 !important;
    opacity:1 !important;
    border-bottom: 2px solid var(--text-primary);
}

.blocking {
    display:block;
}

.bullet-list > a:after {
    display: none;
}

.bullet-list > a {
    font-size: 16px;
    padding: 2vh;
    border: 1.5px solid var(--text-secondary);
    border-radius: 15px;
    width: 72vw;
}